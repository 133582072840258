import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import _get from 'lodash/get'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { SeatMapContainer } from 'tf-checkout-react'

import NoDataImg from '../../../assets/images/no_image.png'
import Head from '../../../components/head'
import Layout from '../../../components/layout'
import { getEvent } from '../../../http_service'
import { setTfCheckoutReactConfigs } from '../../../utils/tf-checkout-config'
import '../style.scss'

const isWindowDefined = typeof window !== 'undefined'

const handleCartSuccessRedirect = (skip_billing_page, event_id = '', hash, total) => {
  if (skip_billing_page) {
    isWindowDefined && window.localStorage.setItem('checkoutData', JSON.stringify({ hash, total }))
    window.location.href = `/billing/checkout?event_id=${event_id}`
  } else {
    event_id && isWindowDefined
      ? window.location.assign(`/billing/billing-info?event_id=${event_id}`)
      : isWindowDefined && window.location.assign(`/billing/billing-info`)
  }
}

const formatDate = (startDate, endDate, formatted_date) => {
  const format = 'MMM DD, YYYY'
  const formattedStartDate = moment(startDate).format(format).toLocaleString()
  const formattedEndDate = moment(endDate).format(format).toLocaleString()
  const isSameDay = moment(formattedStartDate).isSame(formattedEndDate, 'day')

  if (isSameDay) {
    return formatted_date
  } else {
    return formattedStartDate.concat(' - ', formattedEndDate)
  }
}

setTfCheckoutReactConfigs()

const SeatMapPage = ({ serverData }) => {
  const [event, setEvent] = useState({})
  const [venue, setVenue] = useState({})

  useEffect(() => {
    setEvent(_get(serverData, 'event.event', {}))
    setVenue(_get(serverData, 'event.venue', {}))
  }, [serverData])

  const {
    name,
    start_date,
    end_date,
    image_full_url,
    // description,
    // id,
    formatted_date,
  } = event

  const { name: venueName, city, country } = venue
  const metaTitle = `${name} | Mana Common`

  const dateInfo = formatDate(start_date, end_date, formatted_date)
  const imgURL = image_full_url ? image_full_url : NoDataImg

  if (!event.id) {
    return null
  }

  return (
    <>
      <Head title={metaTitle} />
      <Layout>
        <div className="event-details-container" itemScope itemType="https://schema.org/Event">
          <div className="mobile-event-image-container">
            <img
              itemProp="image"
              src={imgURL}
              alt="No Data"
              onError={({ currentTarget }) => {
                currentTarget.setAttribute('style', 'display: none;')
              }}
            />
          </div>
          <div className="info-container">
            <p className="name" itemProp="name">
              {name}
            </p>
            <p className="date-venue">
              <span itemProp="startDate">{dateInfo}</span>
              <span>. </span>
              <span itemProp="location" itemScope itemType="https://schema.org/Place">
                <span itemProp="name">
                  {venueName}
                  {'  '}
                </span>
                <span itemProp="address" itemScope itemType="https://schema.org/PostalAddress">
                  <span itemProp="addressLocality">
                    {city}
                    {'  '}
                  </span>
                  {country ? (
                    <meta itemRef="address-microdata" itemProp="addressCountry" content={country.toUpperCase()} />
                  ) : null}
                </span>
              </span>
            </p>
            <div className="divider" />
          </div>
          <div className="main-content-container">
            <div className="mn__event-tickets-container">
              <SeatMapContainer
                event={event}
                mapContainerId="containerId"
                timerMessage={'Reserved for:'}
                ticketDeleteButtonContent={<DeleteForeverIcon />}
                onAddToCartSuccess={({ skip_billing_page, event_id, hash, total }) =>
                  handleCartSuccessRedirect(skip_billing_page, event_id, hash, total)
                }
              />
              <div className="event-image-container">
                <img
                  itemProp="image"
                  src={imgURL}
                  alt="No Data"
                  onError={({ currentTarget }) => {
                    currentTarget.setAttribute('style', 'display: none;')
                  }}
                />
              </div>
            </div>
            <div
              id="containerId"
              style={{
                width: '100%',
                height: '100%',
                margin: '0 auto',
                textAlign: 'center',
              }}
            ></div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export async function getServerData(props) {
  const {
    params: { slug },
    query: { pk, eventId },
  } = props

  try {
    const event = await getEvent(slug, pk)
    return {
      props: {
        event,
        slug,
        pk,
        eventId,
      },
    }
  } catch (error) {
    return {
      props: { error: true, message: error, slug, pk },
    }
  }
}

export default SeatMapPage
